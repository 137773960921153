<template>
	<v-flex v-if="showFinalizeReassignment || showReasignmentChanges" xs12>
		<v-layout>
			<v-flex v-if="showFinalizeReassignment" xs12>
				<r-alert text outlined color="orange" class="grow mb-0">
					<v-row align="center">
						<v-col class="shrink">
							<v-icon medium color="orange">warning</v-icon>
						</v-col>
						<v-col class="grow">
							<p class="black--text font-weight-bold mb-0">We have not finalized your {{ givingNumberName | lowerCase | singularize }} reassignment. No {{ givingNumberName | lowerCase }} from your reassignment have changed.</p>
						</v-col>
					</v-row>
					<v-row align="center">
						<v-col>
							<r-btn class="ml-11" @click="givingNumberState.showRunReassignmentDialog = true">Run Reassignment</r-btn>
						</v-col>
					</v-row>
				</r-alert>
				<r-dialog v-model="givingNumberState.showRunReassignmentDialog" max-width="600" scrollable type="warning">
					<template slot="title">
						Run Reassignment
					</template>
					<div class="my-4">
						<h6 class="font-weight-medium">
							Are you sure you want to reassign numbers?
						</h6>
					</div>
					<p class="mb-12">
						This reassignment will change the numbers of the profiles in the selected query. This action cannot be undone.
					</p>
					<template slot="actions">
						<r-btn color="warning" @click="runReassignment">Yes, Run Reassignment</r-btn>
						<span>&nbsp; or </span>
						<r-btn text @click="closeDialog">Cancel</r-btn>
					</template>
				</r-dialog>
			</v-flex>
			<r-dialog v-model="showReasignmentChanges" max-width="600" scrollable type="primary">
				<template slot="title">
					Reassignment Successful
				</template>
				<p class="my-4">Reassignment is successful. Review changes on the <a @click="donwloadReassignmentCsv">reassignment report</a>.</p>
				<template slot="actions">
					<r-btn class="mt-n6" @click="closeReassigmentChangeDialog">Close</r-btn>
				</template>
			</r-dialog>
		</v-layout>
	</v-flex>
</template>

<script>
import singularize from '@/filters/Singularize';

export default {
	data() {
		return {
			givingNumberState: _REALM.STORE.givingNumberStore.state,
			givingState: _REALM.STORE.givingStore.state,
			showReasignmentChanges: false
		};
	},
	computed: {
		showFinalizeReassignment() {
			var self = this;
			return !_REALM.STORE.siteStore.hasExperiment('REALM-PLUS') && _REALM.TOOLS.isGuid(self.givingNumberState.reassignment.givingNumberReassignmentId) && self.givingNumberState.reassignment.reassignmentRunDate == null;
		},
		givingNumberName() {
			return _REALM.STORE.givingStore.state.settings.givingNumberFieldNamePlural;
		}
	},
	methods: {
		showChangeLogDialog() {
			var self = this;
			_REALM.STORE.givingNumberStore.state.reassignment.reassignmentCsvIsReady = true;
			_REALM.LOADER.hide();
			self.showReasignmentChanges = true;
		},
		pollScheduledReassignmentCsv(reassignmentCsvId) {
			var self = this;
			var options = {
				id: reassignmentCsvId,
				beforeCallback: self.showChangeLogDialog
			};
			_REALM.STORE.reportStore.pollCsvStatus(options);
		},
		runReassignment() {
			var self = this;
			self.closeDialog();
			_REALM.LOADER.show();
			_REALM.STORE.givingNumberStore.runReassignment().then(function(reassignment) {
				if (!_REALM.TOOLS.isNullOrEmpty(reassignment.reassignmentCsvId)) {
					self.pollScheduledReassignmentCsv(reassignment.reassignmentCsvId);
				} else {
					_REALM.LOADER.hide();
					var message = singularize(self.givingNumberName) + ' reassignment successful.';
					_REALM.TOAST.success(message);
				}
				_REALM.STORE.givingNumberStore.state.reassignment = reassignment;
			});
		},
		closeDialog() {
			var self = this;
			self.givingNumberState.showRunReassignmentDialog = false;
		},
		closeReassigmentChangeDialog() {
			var self = this;
			self.showReasignmentChanges = false;
		},
		donwloadReassignmentCsv() {
			var id = _REALM.STORE.givingNumberStore.state.reassignment.reassignmentCsvId;
			var route = _REALM.STORE.reportStore.getReportUrl(id, 'csv');
			window.open(route, '_parent');
		}
	}
};
</script>
