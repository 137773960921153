<template>
	<v-layout v-if="showPending" row wrap class="pending-giving-number-wrapper">
		<v-flex xs12>
			<r-card>
				<template slot="title">
					<p v-if="reassignmentHasRun" class="mb-0 font-weight-bold text-uppercase">Last Reassignment</p>
					<p v-else class="mb-0 font-weight-bold text-uppercase">Pending Reassignment</p>
				</template>
				<template slot="titleActions">
					<v-menu v-if="!reassignmentHasRun || (reassignmentHasRun && pendingCsvIsReady) || (reassignmentHasRun && reassignmentCsvIsReady)" bottom right class="ma-6">
						<template v-slot:activator="{ on, attrs }">
							<v-btn icon v-bind="attrs" v-on="on">
								<v-icon>mdi-dots-vertical</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item v-if="reassignmentHasRun && pendingCsvIsReady" :target="inIframe ? '_blank' : '_self'" @click="donwloadCsv(reassignment.pendingCsvId, reassignment.pendingCreatedByIndividualId)">
								<v-list-item-title>Export Last Reassignment as .csv</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="reassignmentHasRun && reassignmentCsvIsReady" :target="inIframe ? '_blank' : '_self'" @click="donwloadCsv(reassignment.reassignmentCsvId, reassignment.reassignmentRunByIndividualId)">
								<v-list-item-title>Export Reassignment Changes as .csv</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="!reassignmentHasRun" :target="inIframe ? '_blank' : '_self'" @click="displayEditDialog()">
								<v-list-item-title>Edit Reassignment</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="!reassignmentHasRun" :target="inIframe ? '_blank' : '_self'" @click="deleteReassignment()">
								<v-list-item-title>Delete Reassignment</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>
				<v-layout row wrap>
					<v-flex xs12 class="pt-0 mt-n2">
						<hr />
					</v-flex>
					<v-flex xs12 class="pb-0">
						<p class="body-2 text-muted my-0">Number of Profiles</p>
					</v-flex>
					<v-flex xs12 class="my-0 py-0">
						<p class="font-weight-medium">{{ reassignment.numberOfProfiles | numberFormat }}</p>
					</v-flex>
					<v-flex xs12 class="py-0">
						<p class="body-2 text-muted my-0">Starting Number</p>
					</v-flex>
					<v-flex xs12 class="my-0 py-0">
						<p class="font-weight-medium">{{ reassignment.startingNumber }}</p>
					</v-flex>
					<v-flex xs12 class="py-0">
						<p class="body-2 text-muted my-0">Exclude Numbers</p>
					</v-flex>
					<v-flex xs12 class="my-0 py-0">
						<p class="font-weight-medium">{{ reassignment.excludeStartingNumber }} - {{ reassignment.excludeEndingNumber }}</p>
					</v-flex>
					<v-flex xs12 class="py-0">
						<p class="body-2 text-muted my-0">Run Date</p>
					</v-flex>
					<v-flex xs12 class="my-0 py-0">
						<p class="font-weight-medium">{{ reassignment.runDate.format('MM/DD/YYYY') }}</p>
					</v-flex>
					<v-flex v-if="reassignedBySet" xs12 class="py-0">
						<p v-if="reassignmentHasRun" class="body-2 text-muted my-0">Reassignment By</p>
						<p v-else class="body-2 text-muted my-0">Pending Reassignment By</p>
					</v-flex>
					<v-flex v-if="reassignedBySet" xs12 class="my-0 py-0">
						<p class="font-weight-medium">{{ reassignment.reassignedBy }}</p>
					</v-flex>
					<v-flex xs12>
						<hr />
					</v-flex>
					<v-flex v-if="reassignmentHasRun" xs12 class="d-flex justify-center mt-2">
						<r-btn @click="displayEditDialog()">Reassign {{ givingNumberName }}</r-btn>
					</v-flex>
					<v-flex v-if="!reassignmentHasRun && pendingCsvIsReady" xs12 class="d-flex justify-center py-0">
						<r-btn color="default" @click="donwloadCsv(reassignment.pendingCsvId, reassignment.pendingCreatedByIndividualId)">Export CSV</r-btn>
					</v-flex>
				</v-layout>
			</r-card>
		</v-flex>
	</v-layout>
</template>

<script>
import moment from 'moment';

export default {
	data() {
		return {
			reassignment: {},
			givingNumberState: _REALM.STORE.givingNumberStore.state,
			reportStore: _REALM.STORE.reportStore,
			givingState: _REALM.STORE.givingStore.state
		};
	},
	computed: {
		showPending() {
			var self = this;
			// Reassignment requires custom queries, and custom queries require Inform, so we need to check for Inform here.
			const siteHasInform = _REALM.STORE.siteStore.hasClassification(_REALM.STORE.siteStore.enums.SiteClassification.Inform);
			return siteHasInform && !_REALM.STORE.siteStore.hasExperiment('REALM-PLUS') && _REALM.TOOLS.isGuid(self.givingNumberState.reassignment.givingNumberReassignmentId) && self.givingNumberState.reassignment.pendingRunDate != null;
		},
		reassignmentHasRun() {
			var self = this;
			return self.givingNumberState.reassignment.reassignmentRunDate != null;
		},
		pendingCsvIsReady() {
			var self = this;
			var isReady = _REALM.TOOLS.isGuid(self.givingNumberState.reassignment.pendingCsvId) && self.givingNumberState.reassignment.pendingCsvIsReady;
			return isReady;
		},
		reassignmentCsvIsReady() {
			var self = this;
			var isReady = _REALM.TOOLS.isGuid(self.givingNumberState.reassignment.reassignmentCsvId) && self.givingNumberState.reassignment.reassignmentCsvIsReady;
			return isReady;
		},
		inIframe() {
			return _REALM.TOOLS.inIframe();
		},
		givingNumberName() {
			return _REALM.STORE.givingStore.state.settings.givingNumberFieldNamePlural;
		},
		reassignedBySet() {
			var self = this;
			return !_REALM.TOOLS.isNullOrEmpty(self.givingNumberState.reassignment.reassignedBy);
		}
	},
	watch: {
		'givingNumberState.reassignment'(value) {
			var self = this;
			if (value) {
				value.runDate = moment(value.runDate);
				self.reassignment = value;
			}
		}
	},
	methods: {
		displayEditDialog() {
			var self = this;
			self.givingNumberState.showReassignmentDialog = true;
		},
		donwloadCsv(reportId, individualId) {
			_REALM.STORE.givingNumberStore.getReportUrl(reportId, individualId).then(function(result) {
				if (result.success == true) {
					window.open(result.url, '_parent');
				}
			});
		},
		showRunReassignment() {
			var self = this;
			self.givingNumberState.showRunReassignmentDialog = true;
		},
		deleteReassignment() {
			var self = this;
			self.givingNumberState.showDeleteReassignmentDialog = true;
		}
	}
};
</script>
