<template>
	<r-dialog v-model="showReassignmentDialog" max-width="600" scrollable max-height="700" type="primary">
		<template slot="title"> Reassign {{ givingNumberName }} </template>
		<p>Select the query that contains the profiles you want to reassign {{ givingNumberName | lowerCase }} to, and enter the range of numbers you want to use and exclude. Your reassignment will be pending until you apply it.</p>
		<r-form ref="reassignGivingNumberForm">
			<v-layout row wrap>
				<v-flex xs12 class="ml-n2 mt-2">
					<v-flex v-if="individualQuery.length > 0" xs8 class="mb-n4">
						<r-select-list v-model="saveReassignmentArgs.savedQueryId" outlined dense required :options="individualQuery" label="Saved Custom Query"></r-select-list>
					</v-flex>
					<v-flex v-else class="mb-n4">
						<v-alert type="error" text outlined> You must <r-link route="Queries" color="text--white" target="_blank">create a custom query</r-link> and use it here to run the reassignment.</v-alert>
					</v-flex>
					<v-flex xs12>
						<div class="d-flex">
							<v-checkbox v-model="saveReassignmentArgs.clearExcludedProfileNumbers" label="Clear numbers from excluded profiles"></v-checkbox>
							<r-info-tooltip>Select this option to clear all {{ givingNumberName | lowerCase }} from profiles that aren't in the query results. Skip this option if you want to keep {{ givingNumberName | lowerCase }} that are already in use during the reassignment.</r-info-tooltip>
						</div>
					</v-flex>
				</v-flex>
				<v-flex xs3 class="mb-n4">
					<r-text-input v-model="saveReassignmentArgs.startingNumber" outlined dense :min-value="1" :maxlength="9" max-width="50" required type="number" label="Starting Number"></r-text-input>
				</v-flex>
				<v-flex xs12>
					Exclude Numbers
				</v-flex>
				<v-flex xs3 class="pt-0 mb-n8">
					<r-text-input v-model="saveReassignmentArgs.excludeStartingNumber" outlined dense :min-value="1" :maxlength="9" type="number" label="From"></r-text-input>
				</v-flex>
				<v-flex xs3 class="pt-0 mb-n8">
					<r-text-input v-model="saveReassignmentArgs.excludeEndingNumber" outlined dense :min-value="1" :maxlength="9" type="number" label="To"></r-text-input>
				</v-flex>
			</v-layout>
		</r-form>
		<template slot="actions">
			<r-btn :disabled="individualQuery.length < 1 ? 'disabled' : false" @click="saveReassignment">Save Reassignment & CSV</r-btn>
			<span>&nbsp; or </span>
			<r-btn text @click="closeDialog()">Cancel</r-btn>
		</template>
	</r-dialog>
</template>

<script>
export default {
	data() {
		return {
			individualQuery: [],
			saveReassignmentArgs: {},
			givingNumberState: _REALM.STORE.givingNumberStore.state,
			givingState: _REALM.STORE.givingStore.state
		};
	},
	computed: {
		showReassignmentDialog: {
			get() {
				return _REALM.STORE.givingNumberStore.state.showReassignmentDialog;
			},
			set(value) {
				if (!value) {
					this.closeDialog();
				}
			}
		},
		givingNumberName() {
			return _REALM.STORE.givingStore.state.settings.givingNumberFieldNamePlural;
		},
		inIframe() {
			return _REALM.TOOLS.inIframe();
		}
	},
	watch: {
		'givingNumberState.showReassignmentDialog'(value) {
			var self = this;
			if (value && (self.givingNumberState.reassignment.reassignmentRunDate != null || !_REALM.TOOLS.isGuid(self.givingNumberState.reassignment.givingNumberReassignmentId))) {
				self.resetReassignment();
			} else if (value) {
				self.copyReassignment();
			}
		}
	},
	created() {
		this.getSaveQueries();
	},
	methods: {
		getSaveQueries() {
			var self = this;
			_REALM.STORE.reportStore.getSavedQueries().then(function(resp) {
				self.individualQuery = resp;
			});
		},
		copyReassignment() {
			var self = this;
			self.saveReassignmentArgs = JSON.parse(JSON.stringify(self.givingNumberState.reassignment));
			self.saveReassignmentArgs.startingNumber = String(self.saveReassignmentArgs.startingNumber);
			self.saveReassignmentArgs.excludeStartingNumber = self.saveReassignmentArgs.excludeStartingNumber ? String(self.saveReassignmentArgs.excludeStartingNumber) : null;
			self.saveReassignmentArgs.excludeEndingNumber = self.saveReassignmentArgs.excludeEndingNumber ? String(self.saveReassignmentArgs.excludeEndingNumber) : null;
		},
		resetReassignment() {
			var self = this;
			self.saveReassignmentArgs = JSON.parse(JSON.stringify(self.givingNumberState.reassignment));
			self.saveReassignmentArgs.savedQueryId = null;
			self.saveReassignmentArgs.clearExcludedProfileNumbers = false;
			self.saveReassignmentArgs.startingNumber = '1';
			self.saveReassignmentArgs.excludeStartingNumber = null;
			self.saveReassignmentArgs.excludeEndingNumber = null;
		},
		reportCallback() {
			_REALM.STORE.givingNumberStore.state.reassignment.pendingCsvIsReady = true;
		},
		pollScheduledReassignmentCsv(pendingCsvId) {
			var self = this;
			var options = {
				id: pendingCsvId,
				newTab: false,
				fileType: 'csv',
				manualOpen: false,
				callback: self.reportCallback
			};
			_REALM.STORE.reportStore.pollCsvStatus(options);
		},
		validateExcludeStartEnd() {
			var self = this,
				startingNumberIsBlank = _REALM.TOOLS.isNullOrEmpty(self.saveReassignmentArgs.excludeStartingNumber),
				endingNumberIsBlank = _REALM.TOOLS.isNullOrEmpty(self.saveReassignmentArgs.excludeEndingNumber);
			if (!startingNumberIsBlank || !endingNumberIsBlank) {
				if (startingNumberIsBlank || endingNumberIsBlank) {
					_REALM.TOAST.error('Starting and Ending numbers: Both must be blank or both must be provided.');
					return false;
				} else if (parseInt(self.saveReassignmentArgs.excludeEndingNumber) < parseInt(self.saveReassignmentArgs.excludeStartingNumber)) {
					_REALM.TOAST.error('Exclude From must be less than Exclude To.');
					return false;
				}
			}
			return true;
		},
		saveReassignment() {
			var self = this;
			if (self.$refs.reassignGivingNumberForm.validate() && self.validateExcludeStartEnd()) {
				_REALM.LOADER.show();
				_REALM.STORE.givingNumberStore.saveReassignment(self.saveReassignmentArgs).then(function(reassignment) {
					_REALM.LOADER.hide();
					_REALM.TOAST.success(`Pending Reassignment saved. Generating Pending Reassignment Report.`);
					self.pollScheduledReassignmentCsv(reassignment.pendingCsvId);
					_REALM.STORE.givingNumberStore.state.reassignment = reassignment;
					self.closeDialog();
				});
			}
		},
		closeDialog() {
			var self = this;
			self.givingNumberState.showReassignmentDialog = false;
		}
	}
};
</script>
