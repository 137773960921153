<template ref="givingNumberSettingsPage">
	<v-layout class="flex-wrap">
		<run-reassignment></run-reassignment>
		<v-flex xs8>
			<settings></settings>
		</v-flex>
		<v-flex xs4>
			<reassign-giving-numbers></reassign-giving-numbers>
			<pending-reassignment></pending-reassignment>
			<reassignment-dialog></reassignment-dialog>
			<delete-reassignment></delete-reassignment>
		</v-flex>
	</v-layout>
</template>

<script>
import Settings from '@/components/giving/givingNumberSettings/Settings.vue';
import ReassignGivingNumbers from '@/components/giving/givingNumberSettings/ReassignGivingNumbers.vue';
import PendingReassignment from '@/components/giving/givingNumberSettings/PendingReassignment.vue';
import ReassignmentDialog from '@/components/giving/givingNumberSettings/ReassignmentDialog.vue';
import RunReassignment from '@/components/giving/givingNumberSettings/RunReassignment.vue';
import DeleteReassignment from '@/components/giving/givingNumberSettings/DeleteReassignment.vue';

export default {
	components: {
		Settings,
		ReassignGivingNumbers,
		PendingReassignment,
		ReassignmentDialog,
		RunReassignment,
		DeleteReassignment
	}
};
</script>
