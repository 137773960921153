<template>
	<v-layout v-if="showReassign" row wrap class="giving-number-reassign-wrapper">
		<v-flex xs12>
			<r-card class="mb-4">
				<template slot="title"> Reassign {{ givingNumberName }} </template>
				<p>Reset existing {{ givingNumberName | lowerCase }} all at once. If necessary, you can also clear numbers from congregants who no longer require one.</p>
				<r-btn block @click="displayDialog()">Reassign {{ givingNumberName }}</r-btn>
			</r-card>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			reassignDialog: false,
			individualQuery: [],
			saveReassignmentArgs: {},
			givingNumberState: _REALM.STORE.givingNumberStore.state
		};
	},
	computed: {
		showReassign() {
			var self = this;
			// Reassignment requires custom queries, and custom queries require Inform, so we need to check for Inform here.
			const siteHasInform = _REALM.STORE.siteStore.hasClassification(_REALM.STORE.siteStore.enums.SiteClassification.Inform);
			return siteHasInform && !_REALM.STORE.siteStore.hasExperiment('REALM-PLUS') && !_REALM.TOOLS.isGuid(self.givingNumberState.reassignment.givingNumberReassignmentId);
		},
		givingNumberName() {
			return _REALM.STORE.givingStore.state.settings.givingNumberFieldNamePlural;
		}
	},
	methods: {
		displayDialog() {
			var self = this;
			self.givingNumberState.showReassignmentDialog = true;
		}
	}
};
</script>
