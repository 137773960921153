<template>
	<r-dialog v-model="showDeleteReassignmentDialog" max-width="600" scrollable type="warning">
		<template slot="title">
			Delete Reassignment
		</template>
		<div class="my-4">
			<h6 class="font-weight-medium">
				Are you sure you want to delete this reassignment without running it?
			</h6>
		</div>
		<p class="mb-12">
			You have not run this reassignment, so no numbers have changed. If deleted, you will need to reassign the numbers and download the report again.
		</p>
		<template slot="actions">
			<r-btn color="warning" @click="deleteReassignment">Yes, Delete Without Running</r-btn>
			<span>&nbsp; or </span>
			<r-btn text @click="closeDialog()">Cancel</r-btn>
		</template>
	</r-dialog>
</template>

<script>
import singularize from '@/filters/Singularize';

export default {
	data() {
		return {
			givingNumberState: _REALM.STORE.givingNumberStore.state
		};
	},
	computed: {
		showDeleteReassignmentDialog: {
			get() {
				return _REALM.STORE.givingNumberStore.state.showDeleteReassignmentDialog;
			},
			set(value) {
				if (!value) {
					this.closeDialog();
				}
			}
		},
		givingNumberName() {
			return _REALM.STORE.givingStore.state.settings.givingNumberFieldNamePlural;
		}
	},
	methods: {
		deleteReassignment() {
			var self = this;
			_REALM.LOADER.show();
			_REALM.STORE.givingNumberStore.deleteReassignment().then(function(reassignment) {
				_REALM.LOADER.hide();
				var singularName = singularize(self.givingNumberName);
				var message = singularName + ' Reassignment has been deleted.';
				_REALM.TOAST.success(message);
				_REALM.STORE.givingNumberStore.state.reassignment = reassignment;
				self.closeDialog();
			});
		},
		closeDialog() {
			var self = this;
			self.givingNumberState.showDeleteReassignmentDialog = false;
		}
	}
};
</script>
