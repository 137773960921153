<template>
	<v-layout row wrap class="giving-number-settings-wrapper">
		<v-flex xs12>
			<r-card class="mb-4">
				<r-form ref="givingNumberSettingsForm">
					<v-layout row wrap>
						<v-flex>
							<p class="font-weight-bold text-uppercase">Starting Number</p>
							<p class="caption text-muted">When you click Next Number while viewing a profile, you will assign numbers that follow the sequence you set here. Existing numbers will not be affected.</p>
							<v-col class="pl-0" cols="3" md="3" lg="3" xl="3">
								<r-text-input v-model="startingGivingNumber" outlined dense :minValue="1" :maxlength="9" type="number" label="First Number in Sequence" required></r-text-input>
							</v-col>
						</v-flex>
						<v-flex xs12>
							<hr />
						</v-flex>
						<v-flex>
							<p class="mt-6 font-weight-bold text-uppercase">Reuse {{ givingNumberName }}</p>
							<r-radio-group v-model="isReusingGivingNumbers" class="my-0">
								<r-radio label="Yes, reuse numbers" :value="true"></r-radio>
								<p class="pl-8 caption text-muted">When you click Next Number while viewing a profile, it will reuse a number from a profile that's been deleted, merged, or married.</p>
								<r-radio label="No, use the next highest number in sequence" :value="false"></r-radio>
								<p class="pl-8 caption text-muted">When you click Next Number while viewing a profile, it will assign the number that follows your current highest number.</p>
							</r-radio-group>
							<r-btn @click="saveSettings">Save</r-btn>
						</v-flex>
					</v-layout>
				</r-form>
			</r-card>
		</v-flex>
	</v-layout>
</template>

<script>
import singularize from '@/filters/Singularize';

export default {
	components: {},
	data() {
		return {
			givingNumberStore: _REALM.STORE.givingNumberStore,
			givingState: _REALM.STORE.givingStore.state,
			isReusingGivingNumbers: true,
			startingGivingNumber: '1'
		};
	},
	computed: {
		givingNumberName() {
			return _REALM.STORE.givingStore.state.settings.givingNumberFieldNamePlural;
		}
	},
	created() {
		this.loadSettings();
		this.loadReassignment();
	},
	methods: {
		loadSettings() {
			var self = this;
			_REALM.STORE.givingStore.settings().then(function() {
				var hasGivingSettings = _REALM.TOOLS.isGuid(self.givingState.settings.siteId);

				self.isReusingGivingNumbers = hasGivingSettings ? self.givingState.settings.isReusingGivingNumbers : true;
				self.startingGivingNumber = hasGivingSettings ? String(self.givingState.settings.startingGivingNumber) : '1';
			});
		},
		loadReassignment() {
			_REALM.STORE.givingNumberStore.reassignment();
		},
		saveSettings() {
			var self = this;
			if (self.$refs.givingNumberSettingsForm.validate()) {
				_REALM.LOADER.show();
				var givingNumberSettings = {
					isReusingGivingNumbers: self.isReusingGivingNumbers,
					startingGivingNumber: self.startingGivingNumber
				};

				self.givingNumberStore.saveSettings(givingNumberSettings).then(function() {
					var singularName = singularize(self.givingNumberName);
					var message = singularName + ' settings have been saved.';
					_REALM.TOAST.success(message);
					_REALM.LOADER.hide();
				});
			}
		}
	}
};
</script>
